import { createParagraphBlock, isTextBlock } from 'editor-content/Block.ts';
import { textBlockInsertTextStrategy } from '../../../../../editor/blocks/textBlocksStrategies/textBlockReplaceSelectedContentStrategy.js';
import pasteText from '../../../../../editor/actions/copyPaste/pasteText.js';
import textBlockGetEndOfBlockSelectionStrategy from '../../../../../editor/blocks/textBlocksStrategies/textBlockGetEndOfBlockSelectionStrategy.js';
import { contentSelection } from '../../../../../editor/selection/contentSelection/ContentSelection.js';
import { HydratedBlock } from '../../../../../types/HydratedBlock.js';

export default pasteText<HydratedBlock>(
  (block) => ({
    getEndOfBlockSelection: () =>
      isTextBlock(block)
        ? textBlockGetEndOfBlockSelectionStrategy(block)
        : contentSelection(0),
    insertText: (textNodes, selection) => {
      if (!isTextBlock(block)) {
        return;
      } else {
        return textBlockInsertTextStrategy(block, selection, textNodes);
      }
    },
  }),
  createParagraphBlock,
);
