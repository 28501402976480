import splitTextNodesFromContentSelection from '../../../../../editor/blocks/textNode/splitTextNodesFromContentSelection.js';
import {
  isTextBlock,
  newTextBlockOfType,
  updateTextBlock,
} from 'editor-content/Block.js';
import { getTextFromNodes } from 'editor-content/TextNode.js';
import { contentSelection } from '../../../../../editor/selection/contentSelection/ContentSelection.js';
import getTextBlockLength from '../../../../../editor/blocks/textBlocksStrategies/getTextBlockLength.js';
import pasteBlocks from '../../../../../editor/actions/copyPaste/pasteBlocks.js';
import textBlockGetEndOfBlockSelectionStrategy from '../../../../../editor/blocks/textBlocksStrategies/textBlockGetEndOfBlockSelectionStrategy.js';
import { HydratedBlock } from '../../../../../types/HydratedBlock.js';

// editor has list of pasted blocks
// editor finds selection at end of pasted blocks
//   - asking colleagues "what is the selection at the end of you"
// editor calls selected block colleague with pasted blocks and new selection
// selected block colleague returns new content patch?

export default pasteBlocks<HydratedBlock>((block) => ({
  getEndOfBlockSelection: () =>
    isTextBlock(block)
      ? textBlockGetEndOfBlockSelectionStrategy(block)
      : contentSelection(0),
  splitBlock: (selection) => {
    if (!isTextBlock(block)) {
      return [[], []];
    }

    if (getTextBlockLength(block) === 0) {
      return [[], []];
    }

    const [beforeContent, , afterContent] = splitTextNodesFromContentSelection(
      block.content,
      selection,
    );

    const afterContentIsEmpty = getTextFromNodes(afterContent).length === 0;
    const beforeContentIsEmpty = getTextFromNodes(beforeContent).length === 0;

    // at start of block (collapsed or expanded)
    if (beforeContentIsEmpty) {
      return [[], [updateTextBlock(block, afterContent)]];
    }

    // at end of block (collapsed or expanded)
    if (afterContentIsEmpty) {
      return [[updateTextBlock(block, beforeContent)], []];
    }

    // in middle of block
    return [
      [updateTextBlock(block, beforeContent)],
      [newTextBlockOfType(block, afterContent)],
    ];
  },
}));
