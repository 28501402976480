import ContentSelection from '../../selection/contentSelection/ContentSelection.js';
import { EditorStateGeneric } from '../../EditorStateGeneric.js';
import replaceSelectionWith from '../../../pages/zeck/editor/BodyEditor/replaceSelectionWith.js';
import { textSelection } from '../../selection/TextSelection.js';
import { TextNode } from 'editor-content/TextNode.js';

const pasteText =
  <BlockType>(
    generateBlockEditor: (b: BlockType) => {
      getEndOfBlockSelection: () => ContentSelection;
      insertText: (
        textNodes: TextNode[],
        selection: ContentSelection,
      ) => { block: BlockType; selection: ContentSelection } | void;
    },
    createDefaultBlock: (content: TextNode[]) => BlockType,
  ) =>
  (
    initialState: EditorStateGeneric<BlockType>,
    textNodes: TextNode[],
  ): EditorStateGeneric<BlockType> | void => {
    return replaceSelectionWith(initialState, {
      textSelection(selectedBlock, selection) {
        const insertResult = generateBlockEditor(selectedBlock).insertText(
          textNodes,
          selection,
        );

        if (insertResult) {
          return {
            contentSubset: [insertResult.block],
            selection: textSelection(0, insertResult.selection),
          };
        }

        const newBlock = createDefaultBlock(textNodes);
        const newBlockSelection =
          generateBlockEditor(newBlock).getEndOfBlockSelection();

        return {
          contentSubset: [newBlock],
          selection: textSelection(0, newBlockSelection),
        };
      },
      blockSelection() {
        const newBlock = createDefaultBlock(textNodes);
        const newBlockSelection =
          generateBlockEditor(newBlock).getEndOfBlockSelection();
        return {
          contentSubset: [newBlock],
          selection: textSelection(0, newBlockSelection),
        };
      },
    });
  };

export default pasteText;
