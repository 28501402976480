// a functional component that renders a css tooltip around a child
import cx from 'classnames';
import styles from './Tooltip.module.scss';

type TooltipProps = {
  children: React.ReactNode;
  active?: boolean;
  tipText: string;
};

export default function Tooltip({
  children,
  active = false,
  tipText,
}: TooltipProps) {
  return (
    <div className={cx(active && styles.tooltip__control_wrap)}>
      {children}
      <div className={styles.tooltip__wrap}>
        <div className={styles.tooltip__text}>{tipText}</div>
      </div>
    </div>
  );
}
