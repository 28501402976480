import React from 'react';
import { CommentsState } from './useComments/useComments.js';
import { PublishedSection } from 'api-client/types.js';
import cx from 'classnames';
import styles from './CommentsSidebar.module.scss';
import IconButton from '../../../../../design-system/atoms/IconButton.js';
import CommentsActionBar from './commentsActionBar/CommentsActionBar.js';
import { motion } from 'framer-motion';
import { CommentsSection } from './commentsSection/CommentsSection.js';
import CommentsSidebarModalLayer from '../CommentsSidebarModalLayer.js';
import { preventDefault } from '../../../../../editor/domFacing/events/isKeyMatch.js';

function CommentsFilteredEmpty({
  filterState,
  onClear,
}: {
  filterState: 'resolved' | 'starred';
  onClear: () => void;
}) {
  let filterText: string;

  switch (filterState) {
    case 'resolved':
      filterText = 'resolved';
      break;
    case 'starred':
      filterText = 'starred';
      break;
  }

  return (
    <div className={styles.commentsSidebar__emptyContainer}>
      <p className={styles.commentsSidebar__emptyContainerText}>
        No comments have
        <br /> been {filterText}
      </p>
      <a
        className={styles.commentsSidebar__emptyContainerClear}
        href="#"
        onClick={preventDefault(onClear)}
      >
        Clear Filter
      </a>
    </div>
  );
}

const CommentsSidebar: React.FC<{
  isFullscreenMode: boolean;
  commentsState: NonNullable<CommentsState>;
  publishedSections: PublishedSection[];
}> = ({ isFullscreenMode, commentsState, publishedSections }) => (
  <div
    className={cx(
      styles.commentsSidebar,
      isFullscreenMode && styles.commentsSidebar_fullscreen,
    )}
  >
    <div className={styles.commentsSidebar__header}>
      <IconButton
        aria-label="hide-comments"
        name="doubleChevronRight"
        onClick={commentsState.closeComments}
        className={styles.commentsSidebar__closeButton}
      />
      <span>Comments ({commentsState.totalCommentCount})</span>
    </div>
    <CommentsActionBar commentsState={commentsState} />

    <div className={styles.commentsSidebar__main}>
      {commentsState.filterState && !commentsState.filteredCommentsExist ? (
        <CommentsFilteredEmpty
          filterState={commentsState.filterState}
          onClear={() => commentsState.setFilter(null)}
        />
      ) : (
        publishedSections.map((publishedSection) => (
          <motion.div
            layout
            key={publishedSection.sectionId}
            transition={{ duration: 0.2 }}
            style={{ backgroundColor: 'white' }}
          >
            <CommentsSection
              key={publishedSection.sectionId}
              state={commentsState.forSection(publishedSection)}
            />
          </motion.div>
        ))
      )}
    </div>
    <CommentsSidebarModalLayer />
  </div>
);

export default CommentsSidebar;
