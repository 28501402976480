import ErrorStackParser from 'error-stack-parser';

const expectedDomains: string[] = ['localhost', 'zeck.app'];

const currentLocation =
  !!window && !!window.location
    ? window.location
    : {
        host: null,
        toString: function toString() {
          return null;
        },
      };

const validDomains = [...expectedDomains, currentLocation.host ?? ''];

const isValidDomain = (fileName: string) => {
  return validDomains.some((domain) => fileName.indexOf(domain) !== -1);
};

const getTopOfStack = (error: Error | undefined) => {
  if (!error) {
    return null;
  }
  const errorStack = ErrorStackParser.parse(error) ?? [];
  if (!errorStack.length) {
    return null;
  }
  return errorStack[0];
};

type ErrorWrapper = {
  message: string;
  stack?: string;
  error?: Error;
};

const shouldIgnoreError = (e: ErrorWrapper | undefined) => {
  if (!e) {
    return true;
  }
  // it appears that errors from the backend are not enveloped in a wrapper object
  const topOfStack = getTopOfStack(e.error ?? (e as Error));

  return (
    // ignore undefined errors
    !topOfStack ||
    // is 3rd party script error
    !isValidDomain(topOfStack.fileName ?? '')
  );
};

export default shouldIgnoreError;
