import ContentSelection, {
  contentSelection,
} from '../../selection/contentSelection/ContentSelection.js';
import {
  applySelection,
  ContentPatch,
} from '../../../pages/zeck/editor/BodyEditor/replaceSelectionWith.js';
import EditorData from '../../../pages/zeck/editor/EditorData.js';
import { TextNode } from 'editor-content/TextNode.js';
import { EditorStateGeneric } from '../../EditorStateGeneric.js';
import {
  EditorSelection,
  isBlockSelection,
  isTextSelection,
} from '../../EditorSelection.js';
import splitContentByBlockSelection from '../../selection/splitContentByBlockSelection.js';
import { textSelection } from '../../selection/TextSelection.js';
import { getStartOfBlockSelection } from '../../selection/BlockSelection.js';
import getSelectedBlock from '../../../pages/zeck/editor/BodyEditor/getSelectedBlock.js';
import replaceBlockAt from '../../../pages/zeck/editor/BodyEditor/replaceBlockAt.js';

const cut =
  <BlockType>(
    generateBlockEditor: (b: BlockType) => {
      cut: (
        selection: ContentSelection,
      ) =>
        | [
            newValue: ContentPatch<BlockType[]>,
            returnValue: EditorData<BlockType>,
          ]
        | void;
    },
    createDefaultBlock: (content: TextNode[]) => BlockType,
  ) =>
  (
    initialState: EditorStateGeneric<BlockType>,
  ):
    | [
        newEditorState: {
          content: BlockType[];
          selection: NonNullable<EditorSelection>;
        },
        returnValue: EditorData<BlockType>,
      ]
    | void => {
    const { content, selection } = initialState;

    // this is basically the same as `replaceSelectionWith` but with a return value
    if (isBlockSelection(selection)) {
      const [beforeBlocks, selectedBlocks, afterBlocks] =
        splitContentByBlockSelection(content, selection);

      return [
        {
          content: [...beforeBlocks, createDefaultBlock([]), ...afterBlocks],
          selection: textSelection(
            getStartOfBlockSelection(selection),
            contentSelection(0),
          ),
        },
        { type: 'block', content: selectedBlocks },
      ];
    }

    if (isTextSelection(selection)) {
      const currentBlock = getSelectedBlock(content, selection);
      if (!currentBlock) return;

      const result = generateBlockEditor(currentBlock).cut(selection.offset);
      if (!result) return;

      const contentPatch = result[0];

      return [
        {
          content: replaceBlockAt(
            content,
            contentPatch.contentSubset,
            selection.index,
          ),
          selection: applySelection(selection, contentPatch.selection),
        },
        result[1],
      ];
    }

    return;
  };

export default cut;
