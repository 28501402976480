import useCommentsSectionScrollSync from './useCommentsSectionScrollSync.js';
import { useState } from 'react';

type UIState =
  | { type: 'closed' }
  | {
      type: 'open';
      newCommentOpen: { [sectionId: string]: boolean };
    }
  | { type: 'open-filtered'; filterState: 'resolved' | 'starred' };

export type FilterState = null | 'resolved' | 'starred';

const useCommentsUIState = () => {
  const {
    setCommentSectionRef,
    setSectionContentRef,
    setZeckScrollContainerRef,
    scrollSectionIntoViewIfNeeded,
    scrollCommentsSectionIntoView,
  } = useCommentsSectionScrollSync();

  const [uiState, setUIState] = useState<UIState>({ type: 'closed' });

  const openNewCommentFor = (sectionId: string) => {
    switch (uiState.type) {
      case 'closed': {
        setUIState({
          type: 'open',
          newCommentOpen: {
            [sectionId]: true,
          },
        });
        break;
      }
      case 'open': {
        setUIState({
          type: 'open',
          newCommentOpen: {
            ...uiState.newCommentOpen,
            [sectionId]: true,
          },
        });
        break;
      }
    }
    setTimeout(() => {
      scrollCommentsSectionIntoView(sectionId);
      scrollSectionIntoViewIfNeeded(sectionId);
    }, 1);
  };

  const isFiltered = uiState.type === 'open-filtered';
  const isSidebarOpen = uiState.type === 'open' || isFiltered;

  return {
    setCommentSectionRef,
    setSectionContentRef,
    setZeckScrollContainerRef,
    isSidebarOpen,
    filterState:
      (uiState.type === 'open-filtered' && uiState.filterState) || null,
    openNewCommentFor,
    setFilter: (filterState: FilterState) => {
      if (filterState) {
        setUIState({ type: 'open-filtered', filterState });
      } else {
        setUIState({ type: 'open', newCommentOpen: {} });
      }
    },
    closeComments() {
      setUIState({ type: 'closed' });
    },

    forSection: (sectionId: string) => {
      return {
        canAddNewComment: !isFiltered,
        isNewCommentFormOpen:
          uiState.type === 'open' && !!uiState.newCommentOpen[sectionId],
        scrollSectionIntoViewIfNeeded: () =>
          scrollSectionIntoViewIfNeeded(sectionId),
        closeNewComment: () => {
          switch (uiState.type) {
            case 'closed':
              return setUIState(uiState);

            case 'open':
              return setUIState({
                type: 'open',
                newCommentOpen: {
                  ...uiState.newCommentOpen,
                  [sectionId]: false,
                },
              });
          }
        },
        openSectionComments: () => {
          switch (uiState.type) {
            case 'closed':
              setUIState({
                type: 'open',
                newCommentOpen: {},
              });
              break;

            case 'open':
              setUIState(uiState);
              break;
          }

          // allow component to rerender with sidebar
          setTimeout(() => {
            scrollCommentsSectionIntoView(sectionId);
          }, 1);
        },
      };
    },
  };
};

export default useCommentsUIState;
