import { textSelection, TextSelection } from '../../selection/TextSelection.js';
import {
  bulletedListItemBlock,
  BulletedListItemBlock,
  createBulletedListItemBlock,
} from 'editor-content/BulletedListItemBlock.js';
import { BaseBlockEditor } from '../../BaseBlockEditor.js';
import { BlockEvent } from '../../EditorEvents.js';
import {
  BlockContent,
  getTextFromBlock,
  TextBlock,
  updateTextBlock,
} from 'editor-content/Block.js';
import splitTextNodesFromContentSelection from '../textNode/splitTextNodesFromContentSelection.js';
import { indentBulletedListItem } from '../../../pages/zeck/editor/BodyEditor/indent.js';
import ContentSelection, {
  contentSelection,
  isCollapsed,
} from '../../selection/contentSelection/ContentSelection.js';
import textBlockPressArrowLeftStrategy from '../textBlocksStrategies/textBlockPressArrowLeftStrategy.js';
import getEndOfBlock from '../textBlocksStrategies/getEndOfBlock.js';
import textBlockPressArrowRightStrategy from '../textBlocksStrategies/textBlockPressArrowRightStrategy.js';
import textBlockPressShiftArrowUpStrategy from '../textBlocksStrategies/textBlockPressShiftArrowUpStrategy.js';
import textBlockPressShiftArrowDownStrategy from '../textBlocksStrategies/textBlockPressShiftArrowDownStrategy.js';
import textBlockReplaceSelectedContentStrategy, {
  textBlockInsertTextStrategy,
} from '../textBlocksStrategies/textBlockReplaceSelectedContentStrategy.js';
import textBlockReplaceSelectedContentWithPlaintextStrategy from '../textBlocksStrategies/textBlockReplaceSelectedContentWithPlaintextStrategy.js';
import EditorData from '../../../pages/zeck/editor/EditorData.js';
import textBlockToggleHighlightStrategy from '../textBlocksStrategies/textBlockToggleHighlightStrategy.js';
import textBlockToggleFormatStrategy from '../textBlocksStrategies/textBlockToggleFormatStrategy.js';
import textBlockGetEndOfBlockSelectionStrategy from '../textBlocksStrategies/textBlockGetEndOfBlockSelectionStrategy.js';
import { ContentPatch } from '../../ContentPatch.js';
import textBlockPressShiftEnterStrategy from '../textBlocksStrategies/textBlockPressShiftEnterStrategy.js';
import { HydratedBlock } from '../../../types/HydratedBlock.js';
import { PressDeleteBlockStrategies } from '../../../pages/zeck/editor/BodyEditor/pressDelete.js';
import getTextBlockLength from '../textBlocksStrategies/getTextBlockLength.js';
import { PressBackspaceBlockStrategies } from '../../../pages/zeck/editor/BodyEditor/pressBackspace.js';
import {
  textBlockPressBackspaceStrategy,
  textBlockReceiveBackspaceStrategy,
} from '../textBlocksStrategies/BackspaceStrategies.js';
import textBlockSplitBlockStrategy from '../textBlocksStrategies/splitBlock.js';
import { TextNode } from 'editor-content/TextNode.js';
import textBlockCopyStrategy from '../textBlocksStrategies/textBlockCopyStrategy.js';
import textBlockCutStrategy from '../textBlocksStrategies/textBlockCutStrategy.js';

function isTextBlockEmpty(selectedBlock: TextBlock) {
  return getTextFromBlock(selectedBlock).length === 0;
}

export class BulletedListItemEditorColleague<
  AvailableBlock extends HydratedBlock,
> extends BaseBlockEditor<
  AvailableBlock | BulletedListItemBlock,
  BulletedListItemBlock
> {
  isTextBlock() {
    return true;
  }

  getBlock() {
    return this.block;
  }

  getSelectedContent(contentSelection: ContentSelection) {
    const [, textNodes] = splitTextNodesFromContentSelection(
      this.block.content,
      contentSelection,
    );

    return textNodes;
  }

  replaceSelectedContent(
    selection: ContentSelection,
    data: EditorData<AvailableBlock>,
  ) {
    return textBlockReplaceSelectedContentStrategy(this.block, selection, data);
  }

  replaceSelectedContentWithPlaintext(
    selection: TextSelection,
    plaintext: string,
  ): boolean {
    if (!this.editorDispatch) return false;

    const contentPatch = textBlockReplaceSelectedContentWithPlaintextStrategy(
      this.block,
      selection.offset,
      plaintext,
    );

    if (!contentPatch) return false;

    return this.editorDispatch({
      type: 'replaceBlocks',
      data: {
        contentPatch,
        index: selection.index,
        blocksToReplace: 1,
      },
    });
  }

  toggleHighlight(selection: ContentSelection) {
    return textBlockToggleHighlightStrategy(this.block, selection);
  }

  pressShiftArrowUp(selection: ContentSelection): boolean {
    return textBlockPressShiftArrowUpStrategy(selection);
  }

  pressShiftArrowDown(selection: ContentSelection): boolean {
    return textBlockPressShiftArrowDownStrategy(this.block, selection);
  }

  pressArrowUp(isOnFirstLineOfBlock: () => boolean): boolean {
    return isOnFirstLineOfBlock();
  }

  pressArrowDown(isOnLastLineOfBlock: () => boolean): boolean {
    return isOnLastLineOfBlock();
  }

  pressEnter(
    selection: ContentSelection,
  ): ContentPatch<(BulletedListItemBlock | AvailableBlock)[]> {
    if (isTextBlockEmpty(this.block)) {
      return {
        contentSubset: [this.createDefaultBlock([])],
        selection: textSelection(0, contentSelection(0)),
      };
    }
    const [beforeSelectionTextNodes, , afterSelectionTextNodes] =
      splitTextNodesFromContentSelection(this.block.content, selection);

    return {
      contentSubset: [
        bulletedListItemBlock(
          this.block.id,
          beforeSelectionTextNodes,
          this.block.indent,
        ),
        createBulletedListItemBlock(afterSelectionTextNodes, this.block.indent),
      ],
      selection: textSelection(1, contentSelection(0)),
    };
  }

  pressShiftEnter(
    selection: ContentSelection,
  ): ContentPatch<[BulletedListItemBlock]> {
    return textBlockPressShiftEnterStrategy(this.block, selection);
  }

  getEndOfBlockSelection(): ContentSelection {
    return textBlockGetEndOfBlockSelectionStrategy(this.block);
  }

  isEmptyDefaultBlock(): boolean {
    return false;
  }

  splitBlock(selection: ContentSelection) {
    return textBlockSplitBlockStrategy(this.block, selection);
  }

  insertText(textNodes: TextNode[], selection: ContentSelection) {
    return textBlockInsertTextStrategy(this.block, selection, textNodes);
  }

  copy(selection: ContentSelection) {
    return textBlockCopyStrategy(this.block, selection);
  }

  cut(selection: ContentSelection) {
    return textBlockCutStrategy(this.block, selection);
  }

  pastePlaintext(plaintext: string, selection: ContentSelection) {
    return textBlockReplaceSelectedContentWithPlaintextStrategy(
      this.block,
      selection,
      plaintext,
    );
  }

  Delete: PressDeleteBlockStrategies<BulletedListItemBlock> = {
    pressDelete: (selection) => {
      const isSelectionCollapsedAtEndOfBlock =
        isCollapsed(selection) &&
        selection.anchorOffset >= getTextBlockLength(this.block);

      if (!isSelectionCollapsedAtEndOfBlock) {
        return { type: 'browser-handled' };
      }

      return { type: 'merge' };
    },
    askToMerge: () => ({ type: 'accept-merge', content: this.block.content }),
    receiveMerge: (content) =>
      updateTextBlock(this.block, [...this.block.content, ...content]),
    receiveSelectionFromLeft: () => contentSelection(0),
  };

  Backspace: PressBackspaceBlockStrategies<BulletedListItemBlock> = {
    pressBackspace: (selection: ContentSelection) =>
      textBlockPressBackspaceStrategy(this.block, selection),
    receiveBackspace: (content: BlockContent) =>
      textBlockReceiveBackspaceStrategy(this.block, content),
  };

  dispatch(
    event: BlockEvent<AvailableBlock, BulletedListItemBlock>,
    selection: TextSelection,
  ): boolean {
    if (this.editorDispatch) {
      switch (event.type) {
        case 'format': {
          const contentPatch = {
            contentSubset: [
              textBlockToggleFormatStrategy(
                this.block,
                selection.offset,
                event.data,
              ),
            ],
            selection: {
              index: 0,
              offset: selection.offset,
            },
          };

          return this.editorDispatch({
            type: 'replaceBlocks',
            data: {
              contentPatch,
              index: selection.index,
              blocksToReplace: 1,
            },
          });
        }

        case 'indent': {
          const contentPatch = {
            contentSubset: [indentBulletedListItem(this.block, event.data)],
            selection: {
              index: 0,
              offset: selection.offset,
            },
          };

          return this.editorDispatch({
            type: 'replaceBlocks',
            data: {
              contentPatch,
              index: selection.index,
              blocksToReplace: 1,
            },
          });
        }

        case 'updateBlock': {
          return this.editorDispatch({
            type: 'replaceBlocks',
            data: {
              contentPatch: {
                contentSubset: [event.data.block],
                selection: {
                  index: 0, // relative index
                  offset: selection.offset,
                },
              },
              index: selection.index,
              blocksToReplace: 1,
            },
          });
        }

        case 'pressArrowLeft': {
          const result = textBlockPressArrowLeftStrategy(selection.offset);

          if (result && event.previousBlock) {
            this.editorDispatch({
              type: 'selection',
              data: {
                index: selection.index - 1,
                offset: getEndOfBlock(event.previousBlock),
              },
            });

            return true;
          }
          return false;
        }

        case 'pressArrowRight': {
          const result = textBlockPressArrowRightStrategy(
            selection.offset,
            this.block,
          );

          if (result && event.nextBlock) {
            this.editorDispatch({
              type: 'selection',
              data: {
                index: selection.index + 1,
                offset: contentSelection(0),
              },
            });

            return true;
          }
          return false;
        }

        default: {
          // unhandled events
          return false;
        }
      }
    } else {
      throw new Error('please set an dispatch handler.');
    }
  }
}
