import ContentSelection from '../../selection/contentSelection/ContentSelection.js';
import replaceSelectionWith from '../../../pages/zeck/editor/BodyEditor/replaceSelectionWith.js';
import { textSelection } from '../../selection/TextSelection.js';
import { EditorStateGeneric } from '../../EditorStateGeneric.js';
import BaseBlock from 'editor-content/BaseBlock.js';
import { v4 } from 'uuid';

const pasteBlocks =
  <BlockType extends BaseBlock>(
    generateBlockEditor: (b: BlockType) => {
      getEndOfBlockSelection: () => ContentSelection;
      splitBlock: (
        selection: ContentSelection,
      ) => [before: BlockType[], after: BlockType[]];
    },
  ) =>
  (
    initialState: EditorStateGeneric<BlockType>,
    blocks: BlockType[],
  ): EditorStateGeneric<BlockType> | void => {
    const lastPastedBlock = blocks[blocks.length - 1];
    if (!lastPastedBlock) return initialState;
    const lastBlockEditor = generateBlockEditor(lastPastedBlock);
    const contentSelectionInLastBlock =
      lastBlockEditor.getEndOfBlockSelection();

    const blocksWithNonConflictingIds = blocks.map((block) => {
      if (
        initialState.content.some(
          (existingBlock) => existingBlock.id === block.id,
        )
      ) {
        return {
          ...block,
          id: v4(),
        };
      }
      return block;
    });

    return replaceSelectionWith(initialState, {
      textSelection(selectedBlock, selection) {
        const selectedBlockEditor = generateBlockEditor(selectedBlock);

        const [beforeSplit, afterSplit] =
          selectedBlockEditor.splitBlock(selection);

        const blocksBefore = [...beforeSplit, ...blocksWithNonConflictingIds];

        return {
          contentSubset: [...blocksBefore, ...afterSplit],
          selection: {
            index: blocksBefore.length - 1,
            offset: contentSelectionInLastBlock,
          },
        };
      },
      blockSelection() {
        return {
          contentSubset: blocksWithNonConflictingIds,
          selection: textSelection(
            blocksWithNonConflictingIds.length - 1,
            contentSelectionInLastBlock,
          ),
        };
      },
    });
  };
export default pasteBlocks;
